.flag-button {
    position: absolute;
    top: 4px;
    right: 50px;
    z-index: 10;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    width: 24px;
    height: 16px;
    
  }

  .flag-img {
    width: 48px;
    height: auto;
  }