.card-container{
    display: grid;
    grid-template-columns: 45% 45%;
    gap: 5%;
    justify-content: center;
    align-items: center;
    margin-bottom: 150px;
}

@media (max-width: 1200px) {
    .card-container {
        grid-template-columns: 100%; /* Takes up 90% of the space on smaller screens */
        max-width: 90%; /* Limits to 90% on smaller screens */
        gap: 2.5%;
        margin-left: 5%;
        margin-bottom: 250px;
    }
}
